import Heading from "components/share/Heading";
import { useState, useEffect } from "react";
import { getProductData } from "services/generateFormData";

const options = [
  { id: 1, name: "Торти" },
  { id: 2, name: "Десерти" },
  { id: 3, name: "Картинки" },
  { id: 4, name: "Весільні короваї" },
  { id: 5, name: "Весільні тортики" },
  { id: 6, name: "Кенді бари" },
  { id: 7, name: "Тортики 18+" },
];

export default function AddProductForm({ title, hadleClick, oldProduct }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [priceTitle, setPriceTitle] = useState("грн.");
  const [aboutProduct, setAboutProduct] = useState("");
  const [selectedOption, setSelectedOption] = useState(options[4].id);
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (oldProduct) {
      setName(oldProduct.title);
      setPrice(oldProduct.price);
      setPriceTitle(oldProduct.priceTitle);
      setAboutProduct(oldProduct.description);
      setSelectedOption(oldProduct.categoryID);
    }
  }, [oldProduct]);

  return (
    <div className="w-full max-w-2xl p-5 mx-auto mb-3">
      <Heading title={title} />
      <form className="border p-5 rounded-lg " id="add-prod-form">
        {selectedOption < 4 ? (
          <>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
                  Назва
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  rounded-lg py-2 px-4 mb-3 hover:bg-gray-100 hover:border-purple-500"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="text-gray-600 text-xs italic">
                  Не більше 45 символів
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-1">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
                  Ціна
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  rounded-lg py-2 px-4 mb-3  hover:bg-gray-100 hover:border-purple-500"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
                  Закінчення ціни
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200  rounded-lg py-2 px-4 mb-3  hover:bg-gray-100 hover:border-purple-500"
                  type="text"
                  value={priceTitle}
                  onChange={(e) => setPriceTitle(e.target.value)}
                />
                <p className="text-gray-600 text-xs italic">Зазвичай 'грн.'</p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
                  Напишіть щось про товар
                </label>
                <textarea
                  className="appearance-none block w-full bg-gray-200 rounded-lg py-2 px-4 mb-3  hover:bg-gray-100 hover:border-purple-500"
                  type="text"
                  rows={3}
                  value={aboutProduct}
                  onChange={(e) => setAboutProduct(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
              Виберіть тип:
            </label>
            <div className="relative">
              <select
                required
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="block appearance-none w-full bg-gray-200 py-2 px-4 pr-8 rounded-lg  hover:bg-gray-100 hover:border-purple-500"
              >
                {options.map((item) => (
                  <option key={item.id} value={item.id.toString()}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-3">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
              Виберіть фото:
            </label>
            <input
              className="w-full bg-gray-200 rounded-lg py-2 px-4 mb-3  hover:bg-gray-100 hover:border-purple-500"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            hadleClick(
              getProductData(
                name,
                price,
                priceTitle,
                aboutProduct,
                selectedOption,
                file
              )
            );
          }}
          className="mt-3 w-full justify-center py-2 rounded-lg font-medium text-white bg-purple-600 hover:bg-purple-700"
        >
          {title.split(" ")[0]}
        </button>
      </form>
    </div>
  );
}
