import AdminHeader from "components/AdminHeader";
import { useState, useEffect } from "react";
import CartForm from "components/cart/CartForm";
import { axiosRequest } from "api/axios";
import Loader from "components/preloader/Loader";
import CartProdList from "components/cart/CartProdList";

export function ProductCartPage() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [done, setDone] = useState(false);
  useEffect(() => {
    axiosRequest
      .get(process.env.REACT_APP_DOMAIN_API + "products")
      .then(function ({ data }) {
        setProducts(data.filter((p) => parseInt(p.categoryID) <= 2));
        setDone(false);
      });
  }, []);

  useEffect(() => {
    setTotalPrice(0);
    if (cart.length !== 0) {
      let price = 0;
      cart.forEach((prod) => {
        price += prod.count * prod.price;
      });
      setTotalPrice(price);
    }
  }, [cart]);
  const delProdCart = (id) => {
    setCart(cart.filter((p) => p.id !== id));
  };
  const addProdCart = (id, count) => {
    let newElem = products.find((p) => p.id === id);
    newElem.count = count | 1;
    setCart((cart) => [...cart, newElem]);
  };
  return (
    <>
      <AdminHeader />
      {done ? (
        <Loader />
      ) : (
        <CartForm
          title={"Підрахувати вартість замовлення:"}
          products={products}
          addProdCart={addProdCart}
          totalPrice={totalPrice}
          clearCart={() => setCart([])}
        />
      )}
      <CartProdList cart={cart} delProdCart={delProdCart} />
    </>
  );
}
