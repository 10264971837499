import { useState } from "react";
import Header from "components/AdminHeader";
import AddProductForm from "components/products/ProductForm";
import { axiosRequest } from "api/axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/preloader/Loader";

export function AddProductPage() {
  const [download, setDownload] = useState(false);
  const handleAddProduct = (productData) => {
    setDownload(true);
    axiosRequest
      .post(process.env.REACT_APP_DOMAIN_API + "product", productData)
      .then(function ({ data }) {
        if (data.status === true) {
          setDownload(false);
          toast.success("Товар успішно додано!");
          document.getElementById("add-prod-form").reset();
        } else toast.error("Помилка додавання!");
      })
      .catch(() => {
        toast.error("Помилка додавання!");
        setDownload(false);
      });
  };
  return (
    <>
      <Header />
      {download ? <Loader /> : null}
      <AddProductForm
        hadleClick={handleAddProduct}
        title={"Додати новий продукт:"}
      />
    </>
  );
}
