import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { removeUser } from "store/slices/userSlice";
import { useDispatch } from "react-redux";
const products = [
  {
    id: 1,
    name: "Додати продукти",
    to: "/products/add",
  },
  {
    id: 2,
    name: "Редагувати продукти",
    to: "/products/edit",
  },
  {
    id: 3,
    name: "Популярні продукти",
    to: "/products/popular",
  },
  {
    id: 4,
    name: "Підрахувати вартість",
    to: "/products/cart",
  },
];
const analytics = [
  {
    id: 1,
    name: "Відгуки",
    to: "/analytics/reviews",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminHeader() {
  const dispatch = useDispatch();
  return (
    <Popover className="bg-white dark:bg-gray-900 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
        <div className="flex justify-between items-center border-b-2 border-gray-300 py-3 md:justify-end md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
          <img
              className="h-8 w-auto mr-2"
              src={"https://admin.olgacake.pp.ua/icons/icon-192x192.png"}
              alt=""
            />
            <Link to="/" className="text-2xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">
              OLGACAKE
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:bg-gray-500">
              <span className="sr-only">Відкрити меню</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group rounded-lg p-1 inline-flex items-center text-base font-medium hover:text-gray-900 dark:text-gray-200 dark:hover:text-white"
                    )}
                  >
                    <span>Продукти</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white dark:bg-gray-800 px-5 py-6 sm:gap-8 sm:p-8">
                          {products.map((item) => (
                            <Link
                              key={item.id}
                              to={item.to}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600"
                            >
                              <ChevronRightIcon
                                className="flex-shrink-0 h-6 w-6 text-purple-600 dark:text-purple-300"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-300">
                                  {item.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group rounded-lg p-1 inline-flex items-center text-base font-medium hover:text-gray-900 dark:text-gray-200 dark:hover:text-white"
                    )}
                  >
                    <span>Аналітика</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white  dark:bg-gray-800 px-5 py-6 sm:gap-8 sm:p-8">
                          {analytics.map((item) => (
                            <Link
                              key={item.id}
                              to={item.to}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50  dark:bg-gray-700 dark:hover:bg-gray-600"
                            >
                              <ChevronRightIcon
                                className="flex-shrink-0 h-6 w-6 text-purple-600 dark:text-purple-300"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-300">
                                  {item.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <button
              onClick={() => dispatch(removeUser())}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
            >
              Вихід
            </button>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-800 first-letter:divide-y-2">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Link
                    to="/"
                    className="text-2xl text-gray-600 dark:text-gray-200"
                  >
                    OLGACAKE
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white dark:bg-gray-600 text-gray-400 rounded-md p-2 inline-flex items-center justify-center">
                    <span className="sr-only">Закрити меню</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {products.map((item) => (
                    <Link
                      key={item.id}
                      to={item.to}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-purple-600 dark:text-purple-300"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-300">
                        {item.name}
                      </span>
                    </Link>
                  ))}
                  {analytics.map((item) => (
                    <Link
                      key={item.id}
                      to={item.to}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600"
                    >
                      <ChevronRightIcon
                        className="flex-shrink-0 h-6 w-6 text-purple-600 dark:text-purple-300"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-300">
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <button
                  onClick={() => dispatch(removeUser())}
                  className="w-full flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Вихід
                </button>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
