import { useEffect, useState } from "react";
import Header from "components/AdminHeader";
import ProductsTable from "components/products/ProductsTable";
import { axiosRequest } from "api/axios";
import  PreloaderTable  from 'components/preloader/PreloaderTable';

export function PopularProductPage() {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      axiosRequest
        .get(process.env.REACT_APP_DOMAIN_API + "products/popular/20")
        .then(({ data }) => {
          setData(data);
          setDone(true);
        });
    }, 1000);
  }, []);
  return (
    <>
      <Header />
      {done ? (
        <ProductsTable title={"Популярні продукти:"} data={data} />
      ) : (
        <PreloaderTable />
      )}
    </>
  );
}
