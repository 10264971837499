import Heading from "components/share/Heading";
import { Link } from "react-router-dom";

export default function ProductsTable({ title, data, handleDelete, handleShow, allowEdit }) {
  return (
    <div className="mx-auto max-w-6xl">
      <Heading title={title} />
      <div className="mx-2">
        <table className="border text-center text-gray-600 dark:text-gray-400 w-full">
          <thead>
            <tr className="">
              <th scope="col">ID</th>
              <th scope="col">Назва</th>
              <th scope="col">Відвідування / Дія</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="border p-1">{item.id}</td>
                <td className="border p-1">
                  {item.title || item.full_name || "Без імені"}
                </td>
                <td className="border p-1">
                  {item.count || (
                    <button
                      className="text-white text-sm md:text-base bg-red-500 p-1 rounded-lg hover:bg-red-700 mr-2 mb-1"
                      onClick={() => handleDelete(item.id)}
                    >
                      Видалити
                    </button>
                  )}
                  {handleShow ? (
                    <button
                      className="text-white text-sm md:text-base bg-orange-500 p-1 rounded-lg hover:bg-orange-700"
                      onClick={() => handleShow(item.id)}
                    >
                      {item.showRev === "true" ? "Приховати" : "Показати"}
                    </button>
                  ) : null}
                  {allowEdit ? (
                    <Link
                      className="text-white text-sm md:text-base bg-orange-500 p-1 rounded-lg hover:bg-orange-700"
                      to={"/products/edit/" + item.id}
                    >
                      Редагувати
                    </Link>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
