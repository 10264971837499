import { useDispatch } from "react-redux";
import { setUser } from "store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "api/axios";
import { useEffect, useState } from "react";
import SignInForm from "components/SignInForm";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export function LoginPage() {
  interface loginData {
    login: string;
    pass: string;
  }
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (login) navigate("/products/cart");
    }, 1000);
  }, [login, navigate]);
  const handleLogin = (loginData: loginData) => {
    axiosRequest
      .post(process.env.REACT_APP_DOMAIN_API + "auth", loginData)
      .then(function ({ data }) {
        if (data.status === true) {
          dispatch(
            setUser({
              login: data.login,
              email: data.email,
              token: data.token,
            })
          );
          toast.success("Успішний вхід!");
          setLogin(true);
        }
      })
      .catch(() => toast.error("Дані для входу не вірні!"));
  };
  return (
    <>
      <SignInForm handleClick={handleLogin} />
    </>
  );
}
