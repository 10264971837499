import { Link } from "react-router-dom";

interface Props {
  title?: string;
  subtitle?: string;
}
export default function ErrorComp({ title, subtitle }: Props) {
  return (
    <div className="bg-gradient-to-tr from-slate-600 to-slate-900 text-white min-h-screen flex items-center">
      <div className="container mx-auto p-4 flex flex-wrap items-center">
        <div className="w-full text-center p-4">
          <div className="text-8xl font-medium mb-1">404</div>
          <div className="text-2xl font-medium mb-8">{title}</div>
          {subtitle ? <div className="text-xl mb-8">{subtitle}</div> : null}
          {navigator.onLine ? (
            <Link to="/" className="border border-white rounded-lg p-3">
              Повернутися на головну
            </Link>
          ) : (
            <button
              className="border border-white rounded-lg p-3"
              onClick={() => document.location.reload()}
            >
              Перезавтажити
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
