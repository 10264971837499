import { ToastContainer } from "react-toastify";
import Routes from "routes/Routes";

function App() {
  return (
    <>
      <Routes />
      <ToastContainer position="bottom-right"/>
    </>
  );
}

export default App;
