import Heading from "components/share/Heading";
import CartProd from "./CartProd";

export default function CartProdList({ cart, delProdCart }) {
  return (
    <div className="max-w-2xl p-5 mx-auto">
      <Heading title={"Товари в корзині:"} />
      <div className="border p-5 rounded-lg">
        <ul className="divide-y divide-gray-200">
          {cart.length !== 0 ? (
            cart.map((product) => (
              <CartProd product={product} delProdCart={delProdCart} />
            ))
          ) : (
            <li className="text-center text-gray-700 dark:text-gray-100">
              Товарів немає
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
