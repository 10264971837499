import { useState } from "react";
import { getLoginData } from "services/generateFormData";
export default function SignInForm({ handleClick }) {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2
              className="mt-6 text-center text-3xl 
            font-extrabold text-gray-900 dark:text-gray-100"
            >
              Вхід:
            </h2>
            <p className="mt-2 text-center text-sm text-gray-500 dark:text-gray-300">
              Лише для адміністратора!
            </p>
          </div>
          <form className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="login" className="sr-only">
                  Логін
                </label>
                <input
                  type="login"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Логін"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Пароль
                </label>
                <input
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Пароль"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                className="group relative w-full flex justify-center py-2 px-4  text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(getLoginData(login, pass));
                }}
              >
                Увійти
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
