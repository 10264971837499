import React, { useEffect, useState } from "react";
import Header from "components/AdminHeader";
import CustomTable from "components/products/ProductsTable";
import PreloaderTable from "components/preloader/PreloaderTable";
import { axiosRequest } from "api/axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ReviewsPage() {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      axiosRequest
        .get(process.env.REACT_APP_DOMAIN_API + "reviews/all")
        .then(({ data }) => {
          setData(data);
          setDone(true);
        });
    }, 1000);
  }, []);
  const handleDelete = (id) => {
    axiosRequest
      .get(process.env.REACT_APP_DOMAIN_API + "reviews/" + id + "/delete")
      .then(() => {
        toast.success("Успішно видалено!");
        setData(data.filter((r) => r.id !== id));
      })
      .catch(() => toast.error("Відгук вже видалено або його не існує!"));
  };
  const handleShow = (id) => {
    axiosRequest
      .get(process.env.REACT_APP_DOMAIN_API + "reviews/" + id + "/put")
      .then(() => toast.success("Відгук успішно приховано/показано!"))
      .catch(() => toast.error("Відгук вже видалено або його не існує!"));
  };

  return (
    <>
      <Header />
      {done ? (
        <CustomTable
          title={"Поточні відгуки:"}
          data={data}
          handleDelete={handleDelete}
          handleShow={handleShow}
        />
      ) : (
        <PreloaderTable />
      )}
    </>
  );
}
