import { useSelector } from "react-redux";
interface user {
  login: string | null;
  email: string | null;
  token: string | null;
}
interface userState {
  user: user;
}

export function useAuth() {
  const { login } = useSelector((state: userState) => state.user);

  return {
    isAuth: !!login,
  };
}
