export function getProductData(
  name,
  price,
  priceTitle,
  aboutProduct,
  selectedOption,
  file
) {
  let productData = new FormData();
  productData.append("title", name);
  productData.append("price", price);
  productData.append("priceTitle", priceTitle);
  productData.append("desc", aboutProduct);
  productData.append("categoryID", selectedOption);
  productData.append("file", file);
  return productData;
}
export function getLoginData(username, pass) {
  let loginData = new FormData();
  loginData.append("login", username);
  loginData.append("password", pass);
  return loginData;
}
