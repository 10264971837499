import React from "react";
import PreloaderTitle from "./PreloaderTitle";
import styles from "./Preloader.module.css";

export default function PreloaderTable() {
  const data = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="mx-auto max-w-6xl">
      <div className="pt-5 pb-8 w-auto">
        <PreloaderTitle />
      </div>
      <div className="mx-2">
        <table className="rounded-lg text-center w-full">
          <tbody>
            {data.map((item) => (
              <tr key={item}>
                <th className={styles.skeleton_tablerow} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
