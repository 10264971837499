import { Route, Routes } from "react-router-dom";
import { LoginPage } from "pages/LoginPage";
import { AddProductPage } from "pages/products/AddProductPage";
import { ProductsListPage } from "pages/products/ProductsListPage";
import { EditProductPage } from "pages/products/EditProductPage";
import { PopularProductPage } from "pages/products/PopularProductPage";
import { GuardRoute } from "./GuardRoute";
import { ReviewsPage } from "pages/analytics/ReviewsPage";
import { OfflinePage } from "pages/errors/OfflinePage";
import { NotFountPage } from "pages/errors/NotFountPage";
import { ProductCartPage } from "pages/products/ProductCartPage";

export default function PublicRoutes() {
  return (
    <>
      <Routes>
        {navigator.onLine ? (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<NotFountPage />} />
            <Route
              path="/products/add"
              element={
                <GuardRoute>
                  <AddProductPage />
                </GuardRoute>
              }
            />
            <Route
              path="/products/edit"
              element={
                <GuardRoute>
                  <ProductsListPage />
                </GuardRoute>
              }
            />
            <Route
              path="/products/edit/:id"
              element={
                <GuardRoute>
                  <EditProductPage />
                </GuardRoute>
              }
            />
            <Route
              path="/products/popular"
              element={
                <GuardRoute>
                  <PopularProductPage />
                </GuardRoute>
              }
            />
            <Route
              path="/products/cart"
              element={
                <GuardRoute>
                  <ProductCartPage/>
                </GuardRoute>
              }
            />
            <Route
              path="/analytics/reviews"
              element={
                <GuardRoute>
                  <ReviewsPage />
                </GuardRoute>
              }
            />
          </>
        ) : (
          <Route path="*" element={<OfflinePage />} />
        )}
      </Routes>
    </>
  );
}
