import { useState } from "react";
import Heading from "components/share/Heading";

export default function CartForm({
  title,
  products,
  addProdCart,
  clearCart,
  totalPrice,
}) {
  const [selectedOption, setSelectedOption] = useState(1);
  const [count, setCount] = useState(1);
  return (
    <div className="w-full max-w-2xl p-5 mx-auto mb-3">
      <Heading title={title} />
      <form className="border p-5 rounded-lg" id="add-prod-form">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-xs font-bold mb-2 dark:text-gray-300">
              Виберіть тип:
            </label>
            <div className="relative">
              <select
                required
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="block appearance-none w-full bg-gray-200 py-2 px-4 pr-8 rounded-lg  hover:bg-gray-100 hover:border-purple-500"
              >
                {products.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.id + ", " + item.title}
                  </option>
                ))}
              </select>
            </div>
            <label className="block uppercase tracking-wide text-xs font-bold my-2 dark:text-gray-300">
              Виберіть кількість:
            </label>
            <div className="relative">
              <input
                required
                type={"number"}
                min={1}
                value={count}
                onChange={(e) => setCount(e.target.value)}
                className="block appearance-none w-full bg-gray-200 py-2 px-4 pr-8 rounded-lg  hover:bg-gray-100 hover:border-purple-500"
              />
            </div>
          </div>
        </div>
        <button
          className="mt-3 w-full justify-center py-2 rounded-lg font-medium text-white bg-purple-600 hover:bg-purple-700"
          onClick={(e) => {
            e.preventDefault();
            addProdCart(selectedOption, count);
            setCount(1);
          }}
        >
          Підрахувати
        </button>
        <button
          className="mt-3 w-full justify-center py-2 rounded-lg font-medium text-white bg-red-600 hover:bg-red-700"
          onClick={(e) => {
            e.preventDefault();
            clearCart();
          }}
        >
          Очистити
        </button>
        {totalPrice !== 0 ? (
          <p className="text-center pt-4 font-medium text-gray-700 dark:text-gray-200">
            Загальна вартість товарів: {totalPrice} грн.
          </p>
        ) : null}
      </form>
    </div>
  );
}
