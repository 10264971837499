import { useState, useEffect } from "react";
import Header from "components/AdminHeader";
import { axiosRequest } from "api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddProductForm from "components/products/ProductForm";
import Loader from "components/preloader/Loader";

export function EditProductPage() {
  const [product, setProduct] = useState([]);
  const [done, setDone] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axiosRequest
      .get(process.env.REACT_APP_DOMAIN_API + "product/" + id)
      .then(function ({ data }) {
        setProduct(data);
      });
  }, [id]);

  const handleUpdateProduct = (productData) => {
    setDone(true);
    axiosRequest
      .post(
        process.env.REACT_APP_DOMAIN_API + "product/" + id + "/edit",
        productData
      )
      .then(function ({ data }) {
        if (data.status === true) {
          toast.success("Товар успішно збережено!");
          setDone(false);
          setTimeout(() => {
            navigate("/products/edit");
          }, 1000);
        } else toast.error("Помилка зберігання!");
      })
      .catch(() => {
        toast.error("Помилка зберігання!");
        setDone(false);
      });
  };
  return (
    <>
      <Header />
      {done ? <Loader /> : null}
      <AddProductForm
        title={"Редагування продукта:"}
        hadleClick={handleUpdateProduct}
        oldProduct={product}
      />
    </>
  );
}
