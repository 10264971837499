import React, { useEffect, useState } from "react";
import Header from "components/AdminHeader";
import ProductsTable from "components/products/ProductsTable";
import PreloaderTable from "components/preloader/PreloaderTable";
import { axiosRequest } from "api/axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ProductsListPage() {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      axiosRequest
        .get(process.env.REACT_APP_DOMAIN_API + "products")
        .then(({ data }) => {
          setData(data);
          setDone(true);
        });
    }, 1000);
  }, []);
  const handleDelete = (id) => {
    axiosRequest
      .get(process.env.REACT_APP_DOMAIN_API + "product/" + id + "/delete")
      .then(() => {
        toast.success("Продукт успішно видалено!");
        setData(data.filter((p) => p.id !== id));
      })
      .catch(() => toast.error("Продукт вже видалено або його не існує!"));
  };
  return (
    <>
      <Header />
      {done ? (
        <ProductsTable
          title={"Видалити продукти:"}
          data={data}
          handleDelete={handleDelete}
          allowEdit
        />
      ) : (
        <PreloaderTable />
      )}
    </>
  );
}