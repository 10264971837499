import React from "react";

export default function Heading(props) {
  const { title, size } = props;
  const textSize = size ? "text-" + size + "xl" : "text-2xl";
  return (
    <h1
      className={"text-center text-gray-700 dark:text-gray-200 font-medium pt-5 pb-8 " + textSize}
    >
      {title}
    </h1>
  );
}
