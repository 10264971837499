export default function CartProd({ product, delProdCart }) {
  return (
    <li key={product.id} className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md">
        <img
          src={"https://api.olgacake.pp.ua/" + product.urlToImg}
          alt={product.id}
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
            <h3>{product.title}</h3>
            <p className="ml-4">{product.price + " " + product.priceTitle}</p>
          </div>
          <p className="text-gray-500">
            Кількість {product.count} {product.priceTitle.split("./")[1]}
          </p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="text-gray-500">
            Загальна вартість продукту {product.count * product.price + " грн."}
          </p>

          <div className="flex">
            <button
              type="button"
              className="font-medium text-red-600 hover:text-red-500"
              onClick={() => delProdCart(product.id)}
            >
              Видалити
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}
