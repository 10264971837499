import styles from "./Preloader.module.css";
export default function Loader() {
  return (
    <div className={styles.loader}>
      <div className={styles.loader__row}>
        <div className={styles.loader__item} />
        <div className={styles.loader__item} />
      </div>
    </div>
  );
}
